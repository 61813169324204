import { Injectable } from '@angular/core';

@Injectable()
export class ArchiveService {

  isDossierArchived : boolean | null = null;

  constructor() { 

  }

  public setArchivedFlag(isArchived: boolean): void {
    this.isDossierArchived = isArchived;
  }

  public resetArchivedFlag(): void {
    this.isDossierArchived = null;
  }

  public isArchived(): boolean | null {
    return this.isDossierArchived;
  }

}
