import { RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { SironaHttpInterceptor } from '@shared/common/http/sironaHttpInterceptor';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.MailDistributionServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.CodeServiceProxy,
        ApiServiceProxies.ConfigServiceProxy,
        ApiServiceProxies.MedRegServiceProxy,
        ApiServiceProxies.NaregServiceProxy,
        ApiServiceProxies.PsyRegServiceProxy,
        ApiServiceProxies.AddressAutocompleteServiceProxy,
        ApiServiceProxies.PeopleApplicationServiceProxy,
        ApiServiceProxies.SponsorshipApplicationServiceProxy,
        ApiServiceProxies.LiaTypeServiceProxy,
        ApiServiceProxies.LiaPersonServiceProxy,
        ApiServiceProxies.LiaContactServiceProxy,
        ApiServiceProxies.LiaContactDuringEmploymentServiceProxy,
        ApiServiceProxies.LiaRestrictionsServiceProxy,
        ApiServiceProxies.LiaRestrictionBServiceProxy,
        ApiServiceProxies.LiaInvoiceAddressServiceProxy,
        ApiServiceProxies.LiaDocumentServiceProxy,
        ApiServiceProxies.LiaLanguageSkillServiceProxy,
        ApiServiceProxies.LiaLanguageSkillBServiceProxy,
        ApiServiceProxies.LiaAcademicTitleServiceProxy,
        ApiServiceProxies.LiaEmploymentBServiceProxy,
        ApiServiceProxies.LiaPreviousEmploymentServiceProxy,
        ApiServiceProxies.LiaGuaranteeServiceProxy,
        ApiServiceProxies.LiaPostgraduateTitlePrivateLawServiceProxy,
        ApiServiceProxies.LiaPostgraduateTitleServiceProxy,
        ApiServiceProxies.LiaLicenceInAnotherCantonServiceProxy,
        ApiServiceProxies.LiaDiplomaServiceProxy,
        ApiServiceProxies.LiaProfessionalResponsibilityServiceProxy,
        ApiServiceProxies.EhcServiceProxy,
        ApiServiceProxies.EhcSelfEmploymentServiceProxy,
        ApiServiceProxies.EhcMedicalFieldServiceProxy,
        ApiServiceProxies.EhcLanguageSkillServiceProxy,
        ApiServiceProxies.EhcPracticeServiceProxy,
        ApiServiceProxies.EhcPracticeConfigurationServiceProxy,
        ApiServiceProxies.EhcElectronicPatientRecordServiceProxy,
        ApiServiceProxies.EhcQualityMeasurementServiceProxy,
        ApiServiceProxies.EhcStaffServiceProxy,
        ApiServiceProxies.EhcQualitySupportSystemServiceProxy,
        ApiServiceProxies.EhcPersonServiceProxy,
        ApiServiceProxies.EhcPostgraduateTitleServiceProxy,
        ApiServiceProxies.EhcDocumentServiceProxy,
        ApiServiceProxies.EhcContactServiceProxy,
        ApiServiceProxies.EhcInvoiceAddressServiceProxy,
        ApiServiceProxies.ApplicationServiceProxy,
        ApiServiceProxies.MySironaServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.SponsorshipServiceProxy,
        ApiServiceProxies.CorporateLicenceServiceProxy,
        ApiServiceProxies.CorporateLicenceRestrictionServiceProxy,
        ApiServiceProxies.ClaServiceProxy,
        ApiServiceProxies.ClaDocumentServiceProxy,
        ApiServiceProxies.ClaSponsorshipServiceProxy,
        ApiServiceProxies.ClaBusinessServiceProxy,
        ApiServiceProxies.ClaMedicalFieldDirectorServiceProxy,
        ApiServiceProxies.ClaMedicalFieldDirectorRepresentativeServiceProxy,
        ApiServiceProxies.ClaRangeOfServicesServiceProxy,
        ApiServiceProxies.ClaInfrastructureServiceProxy,
        ApiServiceProxies.ClaMedicalFieldPersonServiceProxy,
        ApiServiceProxies.ClaAssistantPhysicianServiceProxy,
        ApiServiceProxies.ClaPersonelServiceProxy,
        ApiServiceProxies.ClaServiceAreasServiceProxy,
        ApiServiceProxies.ClaTreatmentServiceProxy,
        ApiServiceProxies.ClaNursingBedsServiceProxy,
        ApiServiceProxies.ClaFloorsServiceProxy,
        ApiServiceProxies.ClaAidingResourcesServiceProxy,
        ApiServiceProxies.ClaSecurityServiceProxy,
        ApiServiceProxies.ClaDrugChambersServiceProxy,
        ApiServiceProxies.ClaSanitaryFacilitiesServiceProxy,
        ApiServiceProxies.ClaWheelchairAccessibilityServiceProxy,
        ApiServiceProxies.ClaQualityServiceProxy,
        ApiServiceProxies.ClaServiceAreasServiceProxy,
        ApiServiceProxies.ClaInvoiceAddressServiceProxy,
        ApiServiceProxies.SlasRangeOfProductsServiceProxy,
        ApiServiceProxies.SlasProductPreparationServiceProxy,
        ApiServiceProxies.SlasLocalDrugSalesServiceProxy,
        ApiServiceProxies.SlasDrugProductionServiceProxy,
        ApiServiceProxies.SlasDrugProductionThirdPartyServiceProxy,
        ApiServiceProxies.OlaOnlineDrugSalesServiceProxy,
        ApiServiceProxies.MutServiceProxy,
        ApiServiceProxies.MutPersonServiceProxy,
        ApiServiceProxies.MutApplicantContactServiceProxy,
        ApiServiceProxies.MutApplicantPersonServiceProxy,
        ApiServiceProxies.MutApplicantSponsorshipServiceProxy,
        ApiServiceProxies.MutApplicantBusinessServiceProxy,
        ApiServiceProxies.MutApplicantAdditionalCommentsServiceProxy,
        ApiServiceProxies.MutAddressServiceProxy,
        ApiServiceProxies.MutApplicantBusinessServiceProxy,
        ApiServiceProxies.MutApplicantSponsorshipServiceProxy,
        ApiServiceProxies.MutBusinessChangeServiceProxy,
        ApiServiceProxies.MutBusinessDirectorsServiceProxy,
        ApiServiceProxies.MutSponsorshipServiceProxy,
        ApiServiceProxies.MutSponsorshipDirectorServiceProxy,
        ApiServiceProxies.MutBusinessTerminationServiceProxy,
        ApiServiceProxies.MutBusinessServiceProxy,
        ApiServiceProxies.MutRangeOfServicesServiceProxy,
        ApiServiceProxies.MutDrugProductionServiceProxy,
        ApiServiceProxies.MutEmploymentServiceProxy,
        ApiServiceProxies.MutMedicalFieldDirectorServiceProxy,
        ApiServiceProxies.MutOtherServiceProxy,
        ApiServiceProxies.MutEmploymentTerminationServiceProxy,
        ApiServiceProxies.MutWorkPlaceServiceProxy,
        ApiServiceProxies.MutTransferOfClaServiceProxy,
        ApiServiceProxies.MutAdditionalAcademicTitleServiceProxy,
        ApiServiceProxies.MutPostgraduateTitlePrivateLawServiceProxy,
        ApiServiceProxies.MutTerminationOfClaServiceProxy,
        ApiServiceProxies.MutDocumentServiceProxy,
        ApiServiceProxies.MutationApplicationServiceProxy,
        ApiServiceProxies.SlapServiceProxy,
        ApiServiceProxies.SlapDocumentServiceProxy,
        ApiServiceProxies.SlapPersonServiceProxy,
        ApiServiceProxies.SlapBusinessServiceProxy,
        ApiServiceProxies.SlapPracticeServiceProxy,
        ApiServiceProxies.SlapProductPreparationServiceProxy,
        ApiServiceProxies.SlapRangeOfProductsServiceProxy,
        ApiServiceProxies.SlapInvoiceAddressServiceProxy,
        ApiServiceProxies.SplServiceProxy,
        ApiServiceProxies.SplDocumentServiceProxy,
        ApiServiceProxies.SplPersonServiceProxy,
        ApiServiceProxies.SplNarcoticKnowledgeServiceProxy,
        ApiServiceProxies.SplPostgraduateTitleServiceProxy,
        ApiServiceProxies.AlapServiceProxy,
        ApiServiceProxies.AlasServiceProxy,
        ApiServiceProxies.AlaInvoiceAddressServiceProxy,
        ApiServiceProxies.AlaDocumentServiceProxy,
        ApiServiceProxies.AlaApplicantPersonServiceProxy,
        ApiServiceProxies.AlaApplicantSponsorshipServiceProxy,
        ApiServiceProxies.AlaPersonServiceProxy,
        ApiServiceProxies.AlaContactServiceProxy,
        ApiServiceProxies.AlaDiplomaServiceProxy,
        ApiServiceProxies.AlaBusinessServiceProxy,
        ApiServiceProxies.AlaEmploymentServiceProxy,
        ApiServiceProxies.AlaAcademicTitleServiceProxy,
        ApiServiceProxies.AlaLanguageSkillServiceProxy,
        ApiServiceProxies.AlaCurrentAssistantServiceProxy,
        ApiServiceProxies.AlaTrustworthinessServiceProxy,
        ApiServiceProxies.AlaPostgraduateTitleServiceProxy,
        ApiServiceProxies.AlaPreviousEmploymentServiceProxy,
        ApiServiceProxies.AlaMedicalFieldDirectorServiceProxy,
        ApiServiceProxies.ProfessionRequirementServiceProxy,
        ApiServiceProxies.ProfessionRegisterAffiliationServiceProxy,
        ApiServiceProxies.SponsorshipTypeRegisterAffiliationServiceProxy,
        ApiServiceProxies.ProfessionRequirementGroupServiceProxy,
        ApiServiceProxies.EmploymentServiceProxy,
        ApiServiceProxies.PersonLicenceServiceProxy,
        ApiServiceProxies.TraineePersonLicenceServiceProxy,
        ApiServiceProxies.EmploymentAddressServiceProxy,
        ApiServiceProxies.ApplicationEmploymentPersonServiceProxy,
        ApiServiceProxies.RestrictionServiceProxy,
        ApiServiceProxies.ClaStatusWizardStepServiceProxy,
        ApiServiceProxies.LiaStatusWizardStepServiceProxy,
        ApiServiceProxies.EhcStatusWizardStepServiceProxy,
        ApiServiceProxies.SlapStatusWizardStepServiceProxy,
        ApiServiceProxies.EhcPracticeConfigurationServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.PersonDossierServiceProxy,
        ApiServiceProxies.FileDossierServiceProxy,
        ApiServiceProxies.DocumentManagementSystemServiceProxy,
        ApiServiceProxies.AddressServiceProxy,
        ApiServiceProxies.BusinessServiceProxy,
        ApiServiceProxies.DiplomaServiceProxy,
        ApiServiceProxies.PostgraduateTitleServiceProxy,
        ApiServiceProxies.AssistantServiceProxy,
        ApiServiceProxies.AcademicTitleServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.PendencesServiceProxy,
        ApiServiceProxies.SapInvoiceServiceProxy,
        ApiServiceProxies.SidapServiceProxy,
        ApiServiceProxies.JobsServiceProxy,
        ApiServiceProxies.SensitiveDataServiceProxy,
        ApiServiceProxies.SqlQueryServiceProxy,
        ApiServiceProxies.SqlQueryAdministrationServiceProxy,
        ApiServiceProxies.CriminalPenaltyServiceProxy,
        ApiServiceProxies.AdditionalInformationServiceProxy,
        ApiServiceProxies.MigrationServiceProxy,
        ApiServiceProxies.DocumentManagementSystemTemplateAdministrationServiceProxy,
        ApiServiceProxies.DocumentManagementSystemTemplateServiceProxy,
        ApiServiceProxies.DocumentManagementSystemScanImportServiceProxy,
        ApiServiceProxies.UidRegisterServiceProxy,
        ApiServiceProxies.DistrictServiceProxy,
        ApiServiceProxies.EhcCapServiceProxy,
        ApiServiceProxies.EhcCapWaitListServiceProxy,
        ApiServiceProxies.HealthRegCachingServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: SironaHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
